<template>
  <v-app>
    <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-alert v-model="success" type="success" dismissible>
        {{dialogText}}
      </v-alert>
      <v-alert v-model="error" type="error" dismissible>
        {{dialogText}}
      </v-alert>
      <v-card class="mx-auto mt-5" max-width="400">
        <v-card-title class="indigo white--text text-h5">
          パスワード再発行
        </v-card-title>
        <v-text-field v-if="isCodeIssue" style="margin:0px 25px" class="mt-5" v-model="username" label="ユーザ名" disabled/>
        <v-text-field v-else style="margin:0px 25px" class="mt-5" v-model="username" :rules="userRules" label="ユーザ名"/>
        <v-btn small style="font-size:16px; margin:0px 25px" v-on:click.stop="checkcodeissue()" v-bind:disabled="isSet" color="primary">確認コード発行</v-btn>
        <v-text-field style="margin:0px 25px" class="mt-5" v-model="checkcode" :rules="userRules" label="確認コード"/>
        <v-text-field style="margin:0px 25px" class="mt-5" v-model="newpassword" :rules="[passRules.required, passRules.min]" label="新パスワード"/>
        <v-row class="pa-4" justify="center">
          <v-btn small outlined color="blue" v-on:click="back()" style="font-size:16px; text-transform: none; margin:0px 120px 0px 0px">戻る</v-btn>
          <v-btn small v-on:click.stop="setting()" v-bind:disabled="isSetPass" color="primary" style="font-size:16px">パスワード再発行</v-btn>
        </v-row>
      </v-card>
    </v-form>
    </v-container>
  </v-app>
</template>
<script>
  import {forgotPassword, passwordReset} from '@/js/auth.js'
  
  export default {
    data: () => ({
      loginuser:null,

      hosturl: process.env.VUE_APP_APIURL,
      
      dialogText:null,
      success:false,
      error:false,
      
      username:'',
      checkcode:'',
      newpassword:'',
      
      valid: true,
      
      isSet: true,
      isSetPass: true,
      isCodeIssue: false,
    }),
    computed: {
      userRules() {
        return [
          v => !!v || '必須項目です'
        ]
      },
      passRules() {
        return {
          required: v => !!v || '必須項目です。',
          min: v => v.length >= 8 || '8文字以上入力してください。',
        }
      },
    },
    methods: {
      setting: async function(){
        if(this.$refs.form.validate()){
          var res = await passwordReset(this.username, this.checkcode, this.newpassword)
          if(res){
            console.log(res)
            this.dialogText = 'パスワードの変更に失敗しました。【エラーコード：' + res["code"] + '】';
            this.success = false;
            this.error = true;
          } else {
            this.dialogText = 'パスワードを変更しました。';
            this.success = true;
            this.error = false;
          }
          this.username = '';
          this.checkcode = '';
          this.newpassword = '';
          this.isCodeIssue = false;
          this.returnTop('auto');
        }
      },
      returnTop(action) {
        window.scrollTo({
          top: 0,
          behavior: action
        })
      },
      checkcodeissue: async function(){
        console.log(this.username)
        var res = await forgotPassword(this.username)
        if(res){
          console.log(res)
          this.dialogText = '確認コードの発行に失敗しました。【エラーコード：' + res["code"] + '】';
          this.success = false;
          this.error = true;
        } else {
          this.dialogText = '確認コードを発行しました。';
          this.success = true;
          this.error = false;
          this.isCodeIssue = true;
        }
        this.returnTop('auto');
      },
      back: function(){
        this.$router.replace("/");
      },
    },
    mounted() {
      this.loginuser = this.$store.state.user;
    },
    watch: {
      username: function (newValue) {
        if(newValue){
          this.isSet = false;
        } else {
          this.isSet = true;
        }
        if(newValue && this.checkcode && this.newpassword && this.isCodeIssue){
          this.isSetPass = false;
        } else {
          this.isSetPass = true;
        }
      },
      checkcode: function (newValue) {
        if(newValue && this.username && this.newpassword && this.isCodeIssue){
          this.isSetPass = false;
        } else {
          this.isSetPass = true;
        }
      },
      newpassword: function (newValue) {
        if(newValue && this.username && this.checkcode && this.isCodeIssue){
          this.isSetPass = false;
        } else {
          this.isSetPass = true;
        }
      },
    }
  }
</script>